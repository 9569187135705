import React from "react";
import styled from "styled-components";
import { mediaQueries } from "../utils/constants";

const Container = styled.div`
    padding: 5% 10%;
    background-color: #f8f6f7;
    text-align: center;

    h1 {
        margin: 20px auto;
        padding: 35px;
        position: relative;
        font-size: 2.5em;

        &::after {
            position: absolute;
            content: "";
            display: block;
            width: 5%;
            height: 2px;
            background-color: #333232;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
        }
    }

    p {
        font-size: 1.2em;
        line-height: 2;
        width: 60%;
        margin: auto;
    }

    ${mediaQueries.mobile} {
        padding: 5%;
        h1 {
            padding: 20px;
            font-size: 1.5em;

            &::after {
                width: 25%;
            }
        }

        p {
            width: 90%;
        }
    }
`;

export default function CenterText() {
    return (
        <Container id="aboutContainer">
            <h1>THE EVOLVING WORLD OF LUXURY LIVING</h1>
            <p>
                Toff Homes we introduce ourselves to you as a real estate
                company, that specializes in property development, based in
                Lagos, Nigeria. We sell Lands,residential and commercial houses
                and luxury apartments in Nigeria.
            </p>
        </Container>
    );
}
