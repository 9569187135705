import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import React from "react";
import styled from "styled-components";
import { mediaQueries, projects } from "../utils/constants";
import { FaMapMarkerAlt } from "react-icons/fa";
import { AiFillCheckCircle } from "react-icons/ai";
import { CgLoadbarDoc } from "react-icons/cg";
import { Splide, SplideSlide } from "@splidejs/react-splide";

const Container = styled.div`
    min-height: 100vh;
    padding: 5%;

    .text-cover {
        /* padding: 20px; */
        background-color: #fbfdff;
        .text-c {
            padding: 10px 20px;
            border-bottom: ${({ theme }) => `1px solid ${theme.primaryColor}`};
            border-bottom: 1px solid #ddd;
        }

        h2 {
            font-size: 2em;
            text-transform: capitalize;
        }

        .mid-text {
            p {
                display: flex;
                margin: 10px auto;

                i {
                    display: grid;
                    place-items: center;
                    margin-right: 20px;
                    font-size: 2em;
                    color: ${props => props.theme.primaryColor};
                }
                label {
                    margin-right: 10px;
                }
            }
        }
        .feat {
            display: flex;
            padding: 10px 0px;
            i {
                display: grid;
                place-items: center;
                margin-right: 10px;
                font-size: 2em;
                color: #5a7;
            }
        }

        button {
            display: block;
            color: ${props => props.theme.primaryColor};
            border: ${props => `1px solid ${props.theme.primaryColor}`};
            padding: 15px;
            width: 100%;
            background-color: transparent;
            text-transform: uppercase;
        }
    }
    ${mediaQueries.mobile} {
    }
`;

const ProjectContainer = styled.div`
    position: relative;
    .project {
        width: 90%;
        margin: 10px auto;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }
    .img-holder {
        height: 200px;
        position: relative;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 5px 5px 0px 0px;
            object-fit: cover;
        }
    }

    ${mediaQueries.mobile} {
        height: auto;
        .img-holder {
            height: 300px;
        }
    }
`;
export default function Project({ setVisible, setActiveIndex }) {
    return (
        <Container id="projectContainer">
            <h1>OUR PROJECTS</h1>
            <ProjectContainer>
                <div className="project-grid">
                    <Splide
                        options={{
                            rewind: true,
                            type: "loop",
                            autoplay: true,
                            arrows: false,
                            perPage: 3,
                            breakpoints: {
                                768: {
                                    perPage: 1,
                                },
                            },
                        }}
                    >
                        {projects.map((p, i) => {
                            return (
                                <SplideSlide key={i}>
                                    <div className="project" key={i}>
                                        <div className="img-holder">
                                            <img
                                                src={`assets/img/${p.img}`}
                                                alt="toff"
                                            />
                                        </div>
                                        <div className="text-cover">
                                            <h2 className="text-c">{p.name}</h2>
                                            <div className="mid-text text-c">
                                                <p>
                                                    <i>
                                                        <FaMapMarkerAlt />
                                                    </i>
                                                    <label>Location:</label>
                                                    <span>{p.location}</span>
                                                </p>
                                                <p>
                                                    <i>
                                                        <CgLoadbarDoc />
                                                    </i>
                                                    <label>Title:</label>
                                                    <span>{p.title}</span>
                                                </p>
                                            </div>
                                            <div className="btn-cover text-c">
                                                <button
                                                    onClick={() => {
                                                        setActiveIndex(i);
                                                        setVisible(true);
                                                    }}
                                                >
                                                    Sign Up For Inspection
                                                </button>
                                            </div>
                                            <div className="btm-text text-c">
                                                <h4>Features</h4>
                                                {p.features.map((text, i) => {
                                                    return (
                                                        <p className="feat">
                                                            <i>
                                                                <AiFillCheckCircle />
                                                            </i>
                                                            {text}
                                                        </p>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </SplideSlide>
                            );
                        })}
                    </Splide>
                </div>
            </ProjectContainer>
        </Container>
    );
}

// <div className="text">
//     <div className="main-text">
//         <h2>TOFF ESTATE</h2>
//         <label>3 BEDROOM HOUSES IN BEAUTIFUL SUBURBS</label>
//     </div>
//     <div className="sub-text">
//         <p>
//             Equipped with air conditioning, private pool, 3 on-suite
//             bedrooms and a spacious open living room kitchen area,
//             Toff Estate Residences are an excellent choice for
//             anyone dreaming of their own safe haven. Located in a
//             peaceful, child-friendly neighborhood, Toff Estate has
//             all the amenities that you could expect from a suburb
//             voted 'best in the state' for 3 running years. Whether
//             you want to play soccer with your kids on the lawn, or
//             invite friends over for dinner, there's space for it at
//             Toff Estate.
//         </p>
//     </div>
// </div>;
