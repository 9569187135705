import React from "react";
import styled from "styled-components";
import HeroSection from "../components/HeroSection";
import Navbar from "../components/Navbar";
import { mediaQueries } from "../utils/constants";

const Container = styled.header`
    position: relative;
    overflow: hidden;
    height: 100vh;
    background-color: #eaeaea;
    padding-top: 10vh;
    ${mediaQueries.mobile} {
        height: 90vh;
    }
`;

export const Header = () => {
    return (
        <Container>
            <Navbar />
            <HeroSection />
        </Container>
    );
};
