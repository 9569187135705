import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Home from "./views/Home";

function App() {
    return (
        <ThemeProvider
            theme={{
                primaryColor: "#1C70BB",
            }}
        >
            <Routes>
                <Route path="/" element={<Home />} />
            </Routes>
        </ThemeProvider>
    );
}

export default App;
