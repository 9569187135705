import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { mediaQueries } from "../utils/constants";

const Container = styled.footer`
    position: relative;
    .banner-cover {
        padding: 5% 15%;
    }

    ${mediaQueries.mobile} {
        .banner-cover {
            padding: 5%;
        }
    }
`;
const BottomFooter = styled.div`
    text-align: center;
    padding: 30px;
    background-color: #000;
    a {
        color: #fff;
        font-family: "Bangers", cursive;
        font-size: 1.5em;
        display: block;
        margin: 20px auto;
    }
    p {
        color: #fff;
        margin: 10px auto;
    }
    span {
        color: #aaa;
    }
`;
const Strip = styled.div`
    padding: 40px;
    background-color: rgba(0, 0, 0, 0.8);
    text-align: center;
    cursor: pointer;

    p {
        color: #fff;
        font-size: 1.2em;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: "";
            width: 150px;
            height: 1px;
            background-color: #fff;
            transition: all 0.4s ease;
            margin-right: 20px;
        }
    }

    &:hover {
        background-color: #000;

        p {
            &::before {
                width: 0px;
                /* margin-right: 0px; */
            }
        }
    }
`;

// const Banner = styled.div`
//     background: linear-gradient(45deg, #62aad1, #20a7fa, #19a0fa);
//     min-height: 300px;
//     border-radius: 10px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: 50px 0px;

//     div {
//         text-align: center;
//     }
//     h1 {
//         color: #fff;
//         font-size: 3em;
//         margin: 20px auto;
//     }
//     p {
//         max-width: 50%;
//         margin: 20px auto;
//         line-height: 2;
//         color: #eee;
//     }
//     button {
//         background-color: #fff;
//         display: block;
//         padding: 25px 35px;
//         margin: 30px auto;
//         border: none;
//         color: #19a0fa;
//         border-radius: 5px;
//         width: 20%;
//         box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
//     }
//     ${mediaQueries.mobile} {
//         padding: 20px 0px;
//         h1 {
//             font-size: 1.5em;
//         }
//         p {
//             max-width: 95%;
//         }

//         button {
//             background-color: #fff;
//             display: block;
//             padding: 25px 35px;
//             margin: 30px auto;
//             width: 90%;
//         }
//     }
// `;
export default function Footer() {
    return (
        <Container>
            <div className="banner-cover">
                {/* <Banner>
                    <div>
                        <h1>Become an Associate</h1>
                        <p>
                            For professionals whose work is operational with
                            some complexity: you’re beginning to contribute your
                            thinking to issues and solutions regarding real
                            estate and related issues.
                        </p>
                        <button onClick={() => setShowModal(true)}>
                            Get Started
                        </button>
                    </div>
                </Banner> */}
            </div>

            <Strip>
                <p>SUBSCRIBE TO NEWSLETTER</p>
            </Strip>
            <BottomFooter>
                <Link to="/">TOFF HOMES</Link>
                <p>
                    KM 34 Lekki Epe Expressway, Emperor Estate Mall, Lekki
                    Peninsula Lagos Nigeria
                </p>
                <span>©2022 TOFF HOMES CORP. ALL RIGHTS RESERVED.</span>
            </BottomFooter>
        </Container>
    );
}
