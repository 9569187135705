import React from "react";
import styled from "styled-components";
import { mediaQueries } from "../utils/constants";
const Hero = styled.div`
    position: relative;
    display: grid;
    min-height: 90vh;
    grid-template-columns: 1fr 70%;
    ${mediaQueries.mobile} {
        min-height: 50vh;
        grid-template-columns: 1fr 95%;
    }
`;

const ImgContainer = styled.div`
    position: relative;
    height: 100%;

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    ${mediaQueries.mobile} {
        height: 70%;
    }
`;

const TextContainer = styled.div`
    position: absolute;
    padding: 5%;
    background-color: #eaeaea;
    top: 50%;
    left: 15%;
    width: 40%;
    transform: translateY(-50%);

    &::after {
        content: "";
        position: absolute;
        width: 40%;
        height: 15px;
        background-color: #373f48;
        right: -30%;
        top: 50%;
    }

    h1 {
        font-size: 3em;
    }

    p {
        line-height: 2;
        color: #666;
        margin: 20px auto;
    }

    ${mediaQueries.mobile} {
        top: 90%;
        width: 80%;

        &::after {
            position: absolute;
            width: 15%;
            height: 15px;
            background-color: #373f48;
            right: 80%;
            top: -5%;
            transform: rotate(90deg);
        }

        h1 {
            font-size: 1.5em;
        }

        p {
            line-height: 2;
            color: #666;
            margin: 20px auto;
        }
    }
`;

export default function HeroSection() {
    return (
        <Hero>
            <div></div>
            <ImgContainer>
                <img src="assets/img/house.jpg" alt="hero-house" />
            </ImgContainer>
            <TextContainer>
                <h1>Discover Most Suitable Property for You</h1>
                <p>
                    Finding the perfect place to call home can be hard. Our team
                    of creatives and specialists makes real estate easy.{" "}
                </p>
            </TextContainer>
        </Hero>
    );
}
