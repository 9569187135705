import React from "react";
import styled from "styled-components";
import { GoHome } from "react-icons/go";
import { FiPhoneCall } from "react-icons/fi";
import { FcMoneyTransfer } from "react-icons/fc";
import { mediaQueries } from "../utils/constants";
const Container = styled.div`
    display: grid;
    grid-template-columns: 40% 1fr;
    padding: 5%;
    grid-gap: 2em;
    min-height: 80vh;

    .img-container {
        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;
        }
    }

    .text {
        padding: 20px;
        h1 {
            font-size: 1.5em;
            margin: 20px auto;
        }

        p {
            line-height: 2;
            margin: 20px auto;
        }
        .steps {
            display: flex;
            align-items: center;
            justify-content: space-between;

            /* grid-template-columns: repeat(3, 1fr); */
        }
    }
    ${mediaQueries.mobile} {
        grid-template-columns: 1fr;
        padding: 2.5%;

        .steps {
            flex-direction: column;
        }
    }
`;

const Step = styled.div`
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 5px;
    cursor: pointer;

    i {
        font-size: 2em;
        display: block;
        margin: 15px auto;
    }

    &:nth-child(1) {
        background: linear-gradient(#0c0c1d, #20202e, #252532);

        i,
        h3 {
            color: #f4f4f4;
        }
    }
    ${mediaQueries.mobile} {
        margin: 20px auto;
        width: 85%;
    }
`;

export default function Brief({ setShowModal }) {
    return (
        <Container id="partnershipContainer">
            <div className="img-container">
                <img src={"/assets/img/toff-estate.jpg"} alt="brief" />
            </div>
            <div className="text">
                <h1>Simple Step, Big Move, Become a Partner Today</h1>
                <h2>Why Buying Land In Epe Is A Good Investment.</h2>
                <p>
                    Epe is one of the major divisions in Lagos state located on
                    the northern side of the Lekki lagoon and few kilometers
                    from Ibadan city. A place for the export of fish, maize,
                    cassava, coconuts, vegetables, rubber, palm produce, and so
                    on. The primary occupation of its indigent is fishing. Real
                    estate investment in Nigeria is an investment that is
                    ever-increasing in value. In addition, Epe is the hometown
                    of the former Governor of Lagos State, Akinwunmi Ambode, and
                    current Deputy Governor of Lagos State, Dr Obafemi Hamzat
                </p>
                <p>
                    Investing in landed properties in Epe is now a clarion call
                    to investors who want to make create wealth from property
                    investment in Nigeria. It has been projected that by 2023,
                    Epe will emerge as Lagos industrial hub and mega smart city.
                    Prices of land in Epe are fast appreciating.
                </p>

                <div className="steps">
                    <Step onClick={() => setShowModal(true)}>
                        <i>
                            <GoHome />
                        </i>
                        <h3>Complete our form</h3>
                    </Step>
                    <Step>
                        <i>
                            <FiPhoneCall />
                        </i>
                        <h3>Get Connected</h3>
                    </Step>
                    <Step>
                        <i>
                            <FcMoneyTransfer />
                        </i>
                        <h3>Become a Partner</h3>
                    </Step>
                </div>
            </div>
        </Container>
    );
}
