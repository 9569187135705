import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { mediaQueries } from "../utils/constants";
import { AiOutlineClose } from "react-icons/ai";

const SiteNavLink = ({
    navLinkId,
    scrollToId,
    activeNavLinkId,
    setActiveNavLinkId,
    setToggle,
}) => {
    const handleClick = () => {
        setActiveNavLinkId(navLinkId);
        if (window.innerWidth < 768) setToggle(false);
        document.getElementById(scrollToId).scrollIntoView({
            behavior: "smooth", // gives an ease-in-out effect to our scroll
        });
    };

    return (
        <span
            id={navLinkId}
            className={activeNavLinkId === navLinkId ? "activeClass" : ""}
            onClick={handleClick}
        >
            {navLinkId}
        </span>
    );
};
const navLinks = [
    { navLinkId: "Home", scrollToId: "homeContainer" },
    { navLinkId: "About", scrollToId: "aboutContainer" },
    { navLinkId: "Projects", scrollToId: "projectContainer" },
    { navLinkId: "Partnership", scrollToId: "partnershipContainer" },
    { navLinkId: "Contact", scrollToId: "contactContainer" },
];
const Container = styled.nav`
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    height: 10vh;
    z-index: 10;
    top: 0;
    background-color: #eaeaea;
    .toggle {
        position: absolute;
        top: 55%;
        transform: translateY(-50%);
        right: 10px;
        width: 30px;
        display: none;
        cursor: pointer;
        span {
            width: 100%;
            height: 1px;
            margin-bottom: 8px;
            display: block;
            background-color: #000;
            transition: 0.4s;
        }
    }

    .logo-cover {
        display: flex;
        justify-content: space-between;
        width: 50%;
        a {
            display: flex;
            align-items: center;
            margin-right: 40px;

            p {
                color: #117ab9;
                font-weight: 700;
                font-size: 1.2em;

                label: {
                    color: red;
                }
            }

            img {
                width: 50px;
                height: 50px;
                border-radius: 5px;
                margin-right: 10px;
            }
        }
        .links {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
                cursor: pointer;
            }
            .nav-image {
                display: none;
            }
        }
    }
    .box-link {
        padding-right: 20px;
        a {
            padding: 15px 30px;
            background-color: black;
            color: #fff;
            border-radius: 4px;
        }
    }
    ${mediaQueries.mobile} {
        .toggle {
            /* position: absolute; */
            /* transform: translateY(-50%); */
            /* right: 10px; */
            display: block;
            /* cursor: pointer; */
        }
        .logo-cover {
            .links {
                display: none;
                &.active {
                    display: block;
                    position: absolute;
                    top: -10px;
                    align-items: center;
                    flex: 1;
                    z-index: 5;
                    justify-content: space-between;
                    background-color: #fff;
                    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
                    width: 100%;
                    left: 50%;
                    border-radius: 5px;
                    transform: translateX(-50%);
                    padding: 20px 0px;

                    span {
                        color: #4a5568;
                        display: block;
                        padding: 15px;
                        text-align: left;
                        font-weight: 700;
                    }
                }
                .nav-image {
                    display: flex;
                    align-items: center;
                    padding: 0px 10px;
                    justify-content: space-between;
                    i {
                        font-size: 1.4em;
                        cursor: pointer;
                    }
                    span {
                        font-weight: 700;
                        color: ${props => props.theme.primaryColor};
                    }
                }
            }
        }
    }
`;

export default function Navbar() {
    const [activeNavLinkId, setActiveNavLinkId] = useState("");
    const [toggle, setToggle] = useState(false);
    return (
        <Container>
            <div className="logo-cover">
                <Link to="/">
                    <img src={"assets/img/logo.jpg"} alt="logo" />
                    <p>
                        TOFF<label>HOMES</label>
                    </p>
                </Link>
                <div className={`links ${toggle && "active"}`}>
                    <div className="nav-image">
                        <Link to="/" className="link-image">
                            <img src={"assets/img/logo.jpg"} alt="logo" />
                            <p>
                                TOFF<label>HOMES</label>
                            </p>
                        </Link>
                        <i onClick={() => setToggle(false)}>
                            <AiOutlineClose />
                        </i>
                    </div>
                    {navLinks.map(({ navLinkId, scrollToId }, i) => (
                        <SiteNavLink
                            key={i}
                            navLinkId={navLinkId}
                            scrollToId={scrollToId}
                            activeNavLinkId={activeNavLinkId}
                            setActiveNavLinkId={setActiveNavLinkId}
                            setToggle={setToggle}
                        />
                    ))}
                    {/* <SiteNavLink to="/">Home</SiteNavLink>
                    <SiteNavLink to="/features">Features</SiteNavLink>
                    <SiteNavLink to="/about">About</SiteNavLink>
                    <SiteNavLink to="/projects">Projects</SiteNavLink> */}
                </div>
                <div className="toggle" onClick={() => setToggle(true)}>
                    <span></span>
                    {/* <span></span> */}
                    <span></span>
                </div>
            </div>
            {/* <div className="box-link">
                <Link to="/contact">Contact</Link>
            </div> */}
        </Container>
    );
}
