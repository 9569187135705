import { Modal } from "antd";
import React, { useRef, useState } from "react";
import { Button } from "antd";
import { projects } from "../utils/constants";
import { useForm } from "react-hook-form";
import TextInput from "./Form/TextInput";
import FormTextArea from "./Form/FormTextArea";
import styled from "styled-components";

const Container = styled.div`
    padding: 20px;
`;

export default function InspectionForm({ visible, setVisible, activeIndex }) {
    const modalRef = useRef();

    const submitFn = values => {
        console.log("Success:", values);
        setLoading(false);
    };
    console.log("Tje peop:", activeIndex);
    console.log("Props:", projects[activeIndex]);

    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "onSubmit",
        defaultValues: {
            property: activeIndex ? projects[activeIndex].name : "",
        },
    });

    console.log("The selected index is:", activeIndex);

    return (
        <Modal
            visible={visible}
            title="Property Inspection Form"
            setVisible={setVisible}
            bgColor={"#fff"}
            width="90%"
            nodeRef={modalRef}
            footer={null}
            onCancel={() => setVisible(false)}
        >
            <Container>
                <form onSubmit={handleSubmit(submitFn)}>
                    <TextInput
                        fieldName="property"
                        register={register}
                        errors={errors}
                        isRequired={true}
                        isDisabled={"true"}
                        placeHolder="I want to inspect:"
                    />
                    <TextInput
                        fieldName="name"
                        register={register}
                        errors={errors}
                        isRequired={true}
                        placeHolder="Full Name"
                    />
                    <TextInput
                        fieldName="email"
                        register={register}
                        errors={errors}
                        isRequired={true}
                        placeHolder="Your email address"
                    />
                    <FormTextArea
                        fieldName="message"
                        register={register}
                        errors={errors}
                        isRequired={true}
                        placeHolder="Your Message"
                    />
                    <Button loading={loading} htmlType="submit" type="primary">
                        Send Message
                    </Button>
                </form>
            </Container>
        </Modal>
    );
}
