export const CONSTANTS = {
    PRIMARY_COLOR: "#117AB9",
    SEC_COLOR: "#6FADB8",
};

export const mediaQueries = {
    mobile: "@media(max-width: 768px)",
    desktop: "@media(min-width: 768px)",
};

export const projects = [
    {
        name: "Toff Residence",
        location: "Ibonwon-Eredo Epe",
        title: "Government Excision",
        img: "toff-residence.jpg",
        features: [
            "Electricity 24/7",
            "Clean water",
            "CCTV 24/7",
            "Security",
            "Drainage",
            "Sewage system",
            "Swimming pool",
            "Paved road",
            "Perimeter fencing",
        ],
    },
    {
        name: "EYITAYO ESTATE",
        img: "eyitayo.png",
        location: "IDO, IBADAN ",
        title: "Certificate Of Occupancy ",
        features: [
            "Electricity 24/7",
            "Clean water",
            "CCTV 24/7",
            "Security",
            "Drainage",
            "Sewage system",
            "Swimming pool",
            "Paved road",
            "Perimeter fencing",
        ],
    },
    {
        name: "Toff ESTATE",
        img: "toff3.jpg",
        location: "MONIYA, IBADAN ",
        title: "Certificate Of Occupancy ",
        features: [
            "Electricity 24/7",
            "Clean water",
            "CCTV 24/7",
            "Security",
            "Drainage",
            "Sewage system",
            "Swimming pool",
            "Paved road",
            "Perimeter fencing",
        ],
    },
];
