import React, { useState } from "react";
import styled from "styled-components";
import TextInput from "./Form/TextInput";
import { useForm } from "react-hook-form";
import FormTextArea from "./Form/FormTextArea";
import { Button } from "antd";
import { mediaQueries } from "../utils/constants";

const Container = styled.div`
    position: relative;
    background-color: #fafcff;
    .contact-cover {
        padding: 5%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2em;
    }
    ${mediaQueries.mobile} {
        .contact-cover {
            padding: 5%;
            grid-template-columns: 1fr;
        }
    }
`;

const FormContainer = styled.div`
    padding: 20px;
    width: 70%;
    input,
    textarea {
        padding: 15px 30px;
        border: none;
        border-radius: 20px;
        background-color: #fff;
    }
    .form-field {
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
        margin: 20px auto;
    }

    button {
        display: block;
        background-color: ${props => props.theme.primaryColor};
        border: none;
        padding: 20px;
        width: 100%;
        border-radius: 40px;
        color: #fff;
        font-weight: 700;
        box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
        height: auto;
    }
    ${mediaQueries.mobile} {
        width: 100%;
    }
`;
const Text = styled.div``;
const ContactCard = styled.div`
    margin: 20px auto;
    h1 {
        margin: 20px auto;
    }
    h3 {
        margin: 10px auto;
        font-weight: bold;
    }
`;

export default function Contact() {
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "onSubmit",
        defaultValues: "",
    });

    const submitFn = async values => {
        console.log("The values:", values);
        setLoading(true);
        // const netReq = await createExam(values);
        // if (netReq.error) {
        // setLoading(false);
        // return openErrorNotification(netReq.error);
        // }
        setLoading(false);
        // MySwal.fire({
        //     title: "Exam Created Successfully!",
        //     icon: "success",
        //     confirmButtonText: "Continue",
        // }).then(result => {
        // naviga("/examinations?status=new");
        // });
    };
    return (
        <Container id="contactContainer">
            <div className="contact-cover">
                <FormContainer>
                    <h2>Send us a Message</h2>
                    <form onSubmit={handleSubmit(submitFn)}>
                        <TextInput
                            fieldName="name"
                            register={register}
                            errors={errors}
                            isRequired={true}
                            placeHolder="Full Name"
                        />
                        <TextInput
                            fieldName="email"
                            register={register}
                            errors={errors}
                            isRequired={true}
                            placeHolder="Your email address"
                        />
                        <FormTextArea
                            fieldName="message"
                            register={register}
                            errors={errors}
                            isRequired={true}
                            placeHolder="Your Message"
                        />
                        <Button
                            loading={loading}
                            htmlType="submit"
                            type="primary"
                        >
                            Send Message
                        </Button>
                    </form>
                </FormContainer>
                <Text>
                    <h1>Contact Us</h1>
                    <p>
                        Want to get more information on how to take part in our
                        services? You can contact our representatives with the
                        contact details below.
                    </p>

                    <div className="contact-cards">
                        <ContactCard>
                            <div>
                                <h3>Phone</h3>
                                <p>08094442983 | 08097772983</p>
                            </div>
                        </ContactCard>
                        <ContactCard>
                            <div>
                                <h3>Branch Office</h3>
                                <p>
                                    KM 34 Lekki Epe Expressway, Emperor Estate
                                    Mall, Lekki Peninsula Lagos Nigeria
                                </p>
                            </div>
                        </ContactCard>
                        <ContactCard>
                            <div>
                                <h3>Email</h3>
                                <p>hello@toffhomes.com</p>
                            </div>
                        </ContactCard>
                    </div>
                </Text>
            </div>
        </Container>
    );
}
