import React from "react";
import "../../styles/form.css";

export default function TextInput({
    fieldName,
    register,
    errors,
    placeHolder,
    isRequired,
    maximLength,
    minimLength,
    label,
    type = "text",
    rules = {},
    isDisabled = false,
    otherProps,
}) {
    return (
        <div className="form-field">
            <span>{label}</span>
            <input
                placeholder={placeHolder}
                {...register(fieldName, {
                    ...rules,
                    required: {
                        value: isRequired,
                        message: "This is required",
                    },
                    maxLength: {
                        value: maximLength,
                        message: `Value must be maximum ${maximLength}`,
                    },
                    minLength: {
                        value: minimLength,
                        message: `Value must be minimum ${minimLength}`,
                    },
                })}
                style={{
                    boxShadow: errors[fieldName] ? "0px 0px 2px red" : "none",
                }}
                type={type}
                disabled={isDisabled}
                {...otherProps}
            />
            <p className="error-field">
                {errors[fieldName] && errors[fieldName].message}
            </p>
        </div>
    );
}
