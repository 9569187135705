import React, { useState } from "react";
import Brief from "../components/Brief";
import CenterText from "../components/CenterText";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import InspectionForm from "../components/InspectionForm";
import Partnership from "../components/Partnership";
import Project from "../components/Project";
import { Header } from "../templates/Header";

export default function Home() {
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    return (
        <div id="homeContainer">
            <Header />
            <CenterText />
            <Project
                setVisible={setShowModal2}
                setActiveIndex={setActiveIndex}
            />
            <Brief setShowModal={setShowModal} />
            {/* <Services /> */}
            <Contact />
            <Footer
            //  setShowModal={setShowModal}
            />
            <Partnership visible={showModal} setVisible={setShowModal} />
            <InspectionForm
                visible={showModal2}
                setVisible={setShowModal2}
                activeIndex={activeIndex}
            />
        </div>
    );
}
